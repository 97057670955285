.box {
  max-width: 570px;
  height: 400px;
  border-radius: 27px;
  background: rgb(167, 0, 137);
  background: linear-gradient(
    123deg,
    rgba(167, 0, 137, 1) 13%,
    rgba(125, 70, 224, 1) 71%,
    rgba(89, 0, 250, 1) 100%
  );
  padding: 30px;
  height: 70%;
  vertical-align: middle;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  display: flex;
  align-items: center;
  backdrop-filter: blur(2px);
  justify-content: center;
  height: 100%;
}
