.root {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  max-width: 1600px;
  padding: 40px 70px;
  margin-top: 24px;
  margin: auto;
}
.image {
  width: 242px;
  height: 242px;
  object-position: center;
  overflow: hidden;
  border-radius: 50%;
}
.center {
  max-width: 1600px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.label {
  border: 2px solid white;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #e801be !important;
  cursor: pointer;
}
.button {
  color: white !important;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid white;
}
.icon {
  font-size: 3rem;
}
.eventDetail {
  position: relative;
  bottom: 60px;
  float: right;
  margin-right: 30px;
  left: 90px;
}
@media only screen and (max-width: 610px) {
  .root {
    padding: 40px 20px;
  }
}
