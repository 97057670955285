.inActiveTab {
  padding: 29px 20px 31px 27px;
  display: flex;
  flex-direction: row;
  height: 109px;
  background-color: #fff;
  border: 1px solid #ebeaed;
}
.tab {
  background: #fff0f8;
  border-left: 5px solid #e801be;
}
.rootChat {
  display: flex;
  flex-direction: column;
  padding-left: 17px;
  justify-content: space-between;
  height: 100%;
  flex: 1;
}
.status {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.badge {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #fd1642;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
