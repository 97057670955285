.root {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  max-width: 1600px;
  padding: 40px 70px;
  margin: auto;
  margin-top: 24px;
}
.heading {
  display: flex;
  align-items: center;
}
.iconContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 18px;
}
.profileDetail {
  display: flex;
  gap: 10px;
  /* justify-content: center; */
}
.profile {
  border-radius: 50%;
  width: 73.73px;
  height: 73.73px;
}

.rating {
  display: flex;
  align-items: center;
  gap: 12px;
}
.smProfile {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}
.btnContainer {
  max-width: 1300px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 40px;
}
@media only screen and (max-width: 610px) {
  .root {
    padding: 40px 20px;
  }
}
@media only screen and (max-width: 600px) {
  .profileDetail {
    justify-content: center;
  }
  .price {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
