.root {
  display: flex;
  flex-direction: column;
  /* width: 442px; */
  width: 100%;
  height: 100%;
  border: 2px solid #ebeaed;
  border-left: 0;
}
.tabSection {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 2px solid #ebeaed;
  padding: 41px 0px 0px 29px;
}
.tabSection p {
  color: #989898;
  padding-bottom: 18px;
  cursor: pointer;
}
.activeTab {
  color: #000 !important;
  border-bottom: 3px solid #e801be !important;
}
.tabSection p:last-of-type {
  margin-left: 80px;
}
.emptyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
