.img {
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 30%;
  border-radius: 50%;
}
.profile {
  flex-grow: 3;
  display: flex;
}
.name {
  margin-top: 30px;
}
.container {
  display: flex;
}
.rating {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}
.btn {
  margin-top: 40px;
}

@media only screen and (max-width: 742px) {
  .profile {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .profileImage {
    margin-top: -50px;
  }

  .container {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .name {
    margin-top: 0px;
  }
}
