.root {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.chatSection {
  width: 100%;
}

.tabSection {
  min-width: 410px;
}
@media screen and (max-width: 1200px) {
  .tabSection {
    min-width: 300px;
  }
}
