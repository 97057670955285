.tags {
  margin-top: 27px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.profile {
  border-radius: 50%;
  width: 73.73px;
  height: 73.73px;
  object-fit: cover;
}
