.root {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  max-width: 1600px;
  padding: 40px 70px;
  margin: auto;
  margin-top: 24px;
  min-height: 400px;
  position: relative;
}
.detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}
.profileDetail {
  display: flex;
  gap: 10px;
}
.profile {
  border-radius: 50%;
  width: 73.73px;
  height: 73.73px;
  object-fit: cover;
}
.rating {
  display: flex;
  align-items: center;
  gap: 12px;
}
.quote {
  margin-top: 20px;
}
.tagContainer {
  width: auto;
  height: 49.5px;
  padding: 0px 15px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 7.3px;
}
.icon {
  color: #da22ff;
}
.skills {
  font-weight: 700;
}
.tags {
  margin-top: 27px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.box {
  display: flex;
  gap: 8px;
}
.btn {
  background-color: transparent !important;
  color: #da22ff !important;
  text-decoration: underline !important;
  padding: 0px !important;
  width: 0px;
}
.btnContainer {
  max-width: 1300px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 40px;
  flex-wrap: wrap;
}
.btn:hover {
  background-color: transparent !important;
}
@media only screen and (max-width: 610px) {
  .root {
    padding: 40px 20px;
  }
  .detail {
    flex-wrap: wrap;
  }
}
