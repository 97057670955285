.root {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0px 0px;
}
.content {
}
.img {
  height: 175px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
}
.clock {
  color: #e801be !important;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
}
.paragraph {
  margin: 20px 0px !important;
}
.circle {
  width: 23px;
  height: 23px;
  background-color: #20c9ac;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vendor {
  flex-grow: 3;
  display: flex;
  gap: 10px;
}
.received {
  display: flex;
  align-items: center;
}
.profileContainer {
  display: flex;
}
.arrow {
  color: #e801be;
  cursor: pointer;
}
.profile {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.smallCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #20c9ac;
}
.profileDetail {
  display: flex;
  align-items: center;
  flex-grow: 3;
  gap: 6px;
}
.profileArrow {
}
.btn {
  font-size: 25px;
  padding: 0px !important;
}
.icon {
  font-size: 1.7rem !important;
}
.cancel {
  display: flex;
  margin-top: 15px;
}
.p {
  flex-grow: 3;
}
.status {
  position: relative;
  display: block;
  margin-left: 20px;
  bottom: 160px;
}
