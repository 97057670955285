.input {
  border-radius: 143px;
  border: 2px solid rgb(180, 177, 177);
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #01214d;
}
.input:focus {
  outline: none;
}
.search {
  flex-grow: 3;
  display: flex;
  align-items: center;
}
.container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 8px;
}
.select {
  border-radius: 143px;
  border: 2px solid rgb(180, 177, 177);
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #01214d;
  margin-left: 17px;
}
.select:focus {
  outline: none;
  border: 1px solid #01214d;
}
.searchInput {
  position: relative;
  display: flex;
}
.btn {
  position: relative;
  right: 55px;
}
.cardContainer {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 21px;
  margin-bottom: 90px;
}
@media only screen and (max-width: 838px) {
  .input {
    padding: 10px;
    font-size: 15px;
  }
  .select {
    padding: 10px;
    font-size: 15px;
  }
  .cardContainer {
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 714px) {
  .input {
    padding: 10px;
    font-size: 12px;
  }
  .select {
    padding: 10px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 550px) {
  .input {
    padding: 8px;
    font-size: 12px;
    width: 150px;
  }
  .select {
    padding: 8px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 550px) {
  .container {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .rating {
    width: 65vw;
    margin-top: 4px;
  }
  .search {
    align-items: center;
    justify-content: center;
  }
}
