@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;500;600;700&family=Lexend:wght@500;700&family=Playfair+Display:wght@700;800&family=Poppins:wght@400;600&display=swap");
html,
body {
  padding: 0;
  margin: 0;

  font-family: "Be Vietnam Pro", "Playfair Display", "Poppins", sans-serif;
}
a {
  text-decoration: none !important;
}
* {
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: #28eae9;
  border-radius: 12px;
}
.scroll::-webkit-scrollbar {
  width: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #28eae9;
  border-radius: 12px;
}
.chat {
  max-height: 10rem;
  overflow-y: auto;
}
.chat::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.chat::-webkit-scrollbar-track {
  background: #eeeeee !important;
  border-radius: 5px;
  margin-top: 10px;
}
.chat::-webkit-scrollbar-thumb {
  background-color: #28eae9;
  border-radius: 5px;
}
.input {
  border: 2px solid #e800be;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding: 10px;
}

.input:focus,
.input:active,
.input:hover {
  border: 2px solid #e800be;
}
