.root {
  width: 100%;
  background-color: #fff4fd;
  padding: 30px;
  text-align: left !important;
  display: flex;
  gap: 20px;
  align-items: center;
  flex:1;
}
.root.selected {
  background-color: #ffddf9;
}
.text {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-grow: 3;
}
.icon {
  cursor: pointer;
}
.container {
  text-align: left;
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
}
.mainContainer {
  text-align: left;
  padding: 0 20px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  flex-direction: column;
}
.hidden {
  text-align: left;
  display: none;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1) ease-in;
}
.container p {
  display: block;
}
.inputContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}
.btn {
  margin-top: 20px;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.textarea {
  width: 100%;
}
.textarea:focus {
  outline: none;
  
}
@media only screen and (max-width: 500px) {
  .heading {
    font-size: 12px !important;
  }
  .text {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 3;
  }
}
