.root {
  width: 360px;
  height: 360px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  min-height: 360px;
}
.img {
  width: 360px;
  height: 184px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}
.text {
  padding: 10px 18px;
  flex: 1;
}
.icon {
  color: #e801be;
  cursor: pointer;
}
.vendor {
  flex-grow: 3;
}
.container {
  display: flex;
  padding: 8px 18px;
  flex: 1;
}
.text p {
  margin-top: 10px;
  line-height: 23.7px;
  margin-bottom: 9px;
}
@media only screen and (max-width: 420px) {
  .img {
    width: 320px;
  }
  .root {
    width: 320px;
    height: auto;
  }
}
