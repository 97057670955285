.root {
  max-width: 1300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.profileDetail {
  display: flex;
  gap: 10px;
}
.profile {
  border-radius: 50%;
  width: 73.73px;
  height: 73.73px;
}
.rating {
  display: flex;
  align-items: center;
  gap: 12px;
}
.btnContainer {
  display: flex;
  gap: 100px;
}

@media only screen and (max-width: 1077px) {
  .container {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  form {
    flex-grow: 0;
  }
}
@media only screen and (max-width: 1071px) {
  .root {
    /* flex-direction: column; */
  }
}
@media only screen and(max-width: 373px) {
  .btnContainer {
    gap: 40px;
  }
}
