.container {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.input {
  width: 60px;
  height: 60px;
  font-size: 40px;
  font-weight: bold;
}
