.img {
  width: 100%;
  height: 242px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.status {
  position: relative;
  display: block;
  margin-left: 20px;
  bottom: 210px;
}
.btn {
  border: 2px solid white !important;
}
.eventDetail {
  position: relative;
  bottom: 60px;
  float: right;
  margin-right: 30px;
}
.paragraph {
  margin-top: 15px;
  max-width: 1200px;
  margin-bottom: 45px;
}
.iconGroup {
}
.iconContainer {
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
}
.icon {
  color: #e801be;
  display: flex;
  gap: 20px;

  align-items: flex-start;
}
.select {
  border-radius: 143px;
  border: 2px solid rgb(180, 177, 177);
  padding: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #01214d;
  margin-left: 17px;
}
.vendor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.quoteCardContainer {
  margin-top: 30px;
}
.selectContainer {
  display: flex;
  gap: 20px;
}
@media only screen and (max-width: 900px) {
  .root {
    padding: 15px;
  }
}
@media only screen and (max-width: 600px) {
  .iconGroup {
    width: 100%;
    margin: auto;
  }
  .iconContainer {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 408px) {
  .select {
    padding: 8px;
    font-size: 12px;
  }
}
