.root {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.apiResponse {
  position: absolute;
  top: 10px;
  font-size: 14px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
}
