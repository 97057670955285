.status {
  width: min-content;
  min-width: 85px;
  height: 29px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  color: white !important;
}
