.tagContainer {
  width: auto;
  height: 49.5px;
  padding: 0px 15px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 7.3px;
}
.icon {
  color: #da22ff;
}
.skills {
  font-weight: 700;
}
