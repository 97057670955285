.root {
  height: 100%;
  width: 100%;
}
.profile {
  display: flex;
  align-items: center;
  min-height: 82px;
  border-bottom: 2px solid #ebeaed;
  padding: 0 20px 0 20px;
  justify-content: center;
}
.titleSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.titleSection p {
  font-size: 20px;
  font-weight: 600;
  line-height: 29px;
  color: #01214d;
}
.statusSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}
.statusSection p {
  color: #84818a;
  font-size: 16px;
  font-weight: 500;
  margin-left: 9px;
}
.status {
  background: #14b13b;
  height: 9px;
  width: 9px;
  border-radius: 50%;
}
.offlineStatus {
  background: red;
}
.meetingButton {
  background: #5d5fef !important;
  
}
.inbox {
  min-height: calc(100% - 157px);
  background-color: #fbf8ff;
  display: flex;
  flex-direction: column-reverse;
  padding: 45px 27px;
}

.sendMessage {
  background-color: #fff;
  padding: 0px 29px;
  display: flex;
  height: 72px;
  align-items: center;
  flex-direction: row;
  border-top: 2px solid #ebeaed;
}
.meetingButtons {
}
.iconEmojie,
.addIcon {
  padding: 23px 0px;
}
.input {
  width: 100%;
  padding-left: 11px;
  height: 100%;
}
.input input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
  font-size: 16px;
}
.input input:focus-visible,
.input input:active {
  outline: none;
}
.input input::placeholder {
  color: #84818a;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
}
.sendMessageIcon {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(
    123.73deg,
    #d312b0 13.25%,
    #7d46e0 70.02%,
    #5b00ff 70.02%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.sendMessageIconSection {
  padding: 17px 0px 18px 23px;
}
.sendMessageSection {
  display: flex;
  align-items: center;
  flex-direction: row;
}
