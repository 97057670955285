.intro {
  margin-top: 25px !important;
}
.paragraph {
  margin-top: 26px;
  line-height: 28px;
}
.skills {
  font-weight: 700;
}
.tags {
  margin-top: 27px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.tagContainer {
  width: auto;
  height: 49.5px;
  padding: 0px 15px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 7.3px;
}
.icon {
  color: #da22ff;
}
.gallery {
  margin-top: 20px;
  display: flex;
  width: 100%;
}
.root {
  margin-bottom: 50px;
}
.images {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1364px) {
  .gallery {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .image {
    gap: 0px;
  }
  .grid {
    display: none;
  }
}
