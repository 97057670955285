.input {
  border-radius: 143px;
  border: 2px solid rgb(180, 177, 177);
  padding: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #01214d;
}
.input:focus {
  outline: none;
}
.inputCotainer {
  display: flex;
  margin-top: 29px;
  margin-right: 20px;
}
.select {
  border-radius: 143px;
  border: 2px solid rgb(180, 177, 177);
  padding: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #01214d;
  margin-left: 17px;
}
.search {
  flex-grow: 3;
  display: flex;
  align-items: center;
}
.searchInput {
  flex-grow: 3;
  position: relative;
  display: flex;
}
.btn {
  position: relative;
  right: 60px;
}
.container {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 50px;
}
.root {
  max-width: 1470px;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0px;
}
@media only screen and (max-width: 1000px) {
  .inputCotainer {
    flex-wrap: wrap;
    gap: 10px;
  }
}

@media only screen and (max-width: 752px) {
  .container {
    margin-left: 0px;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 732px) {
  .inputCotainer {
    align-items: center;
    justify-content: center;
  }
  .searchInput {
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 408px) {
  .select {
    padding: 8px;
    font-size: 12px;
  }
  .input {
    padding: 8px;
    font-size: 12px;
  }
}
