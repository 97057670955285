.root {
  padding: 21px 34px;
  margin: 30px 0px;
}
.profile {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.profileContainer {
  display: flex;
  gap: 10px;
}
.text {
  max-width: 900px;
}
.hide {
  display: none;
}
.btnContainer {
  display: flex;

  gap: 20px;
}
.detailContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1542px) {
  .detailContainer {
    flex-wrap: wrap;
    gap: 30px;
  }
}
