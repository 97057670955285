.img {
  width: 100%;
  height: 242px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}
.status {
  position: relative;
  display: block;
  margin-left: 20px;
  bottom: 210px;
}
.btn {
  border: 2px solid white !important;
}
.eventDetail {
  position: relative;
  bottom: 60px;
  float: right;
  margin-right: 30px;
}
.button {
  color: white !important;
  background-color: #e801be !important;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid white;
}
.icon {
  font-size: 3rem;
}
