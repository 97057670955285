.Chat_Head_Message {
  background-color: #e801be;
  border-radius: 4.51724px 4.51724px 0px 4.51724px;
  padding: 9px 13px;
  color: #fff;
  max-width: 34rem;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.User_Chat_Head_Message p {
  color: #000 !important;
}
.Chat_Head_Message p {
  color: #fff;
}
.User_Chat_Head_Message {
  background-color: #fff;
}
.userSendingTime {
  margin-left: 5px;
  margin-top: 7px;
  display: flex;
  justify-content: flex-start;
  color: #808080;
}
.sendingTime {
  float: right;
  margin-top: 7px;
  color: #808080;
  display: flex;
  justify-content: flex-end;
}
.myMessageAlignment {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.otherMessageAlignment {
  margin-top: 1rem;
  align-self: flex-start;
}
