.container {
  display: flex;
}
.icon {
  color: #e801be;
  cursor: pointer;
}
.iconContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 40px;
}
.iconContainer .input {
  border-radius: 143px;
  border: 2px solid rgb(180, 177, 177);
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #01214d;
}
.iconContainer .input:focus {
  outline: none;
  border: 1px solid #01214d;
}
.form {
  flex-grow: 3;
}
.box {
  width: 163px;
  height: 59px;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  gap: 12px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.listCotainer {
  display: flex;
  gap: 20px;
}
.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px;
}
@media only screen and (max-width: 962px) {
  .container {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  form {
    flex-grow: 0;
  }
}
@media only screen and (max-width: 574px) {
  .iconContainer .input {
    border-radius: 143px;
    border: 2px solid rgb(180, 177, 177);
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    color: #01214d;
  }
}
@media only screen and (max-width: 426px) {
  .iconContainer .input {
    border-radius: 143px;
    border: 2px solid rgb(180, 177, 177);
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #01214d;
  }
  .listCotainer {
    display: none;
    gap: 20px;
  }
}
