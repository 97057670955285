.heading {
  margin-top: 20px !important;
}
.paragraph {
  margin-top: 26px !important;
  line-height: 28px !important;
}
ul {
  margin-top: 30px;
}
li {
  list-style-image: url("/src/assets/icon/tick.svg");
  margin-top: 15px;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
