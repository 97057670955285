.links {
  color: #fff;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
.links:hover {
  border-bottom: 2px solid white;
}

.textarea:focus {
  outline: none;
  max-width: 900px;
  width: auto;
}
