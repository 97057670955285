.root {
  display: inline-block;
}
.image {
  width: 100%;
  height: 386.6px;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}
.imageContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.text {
  display: flex;
  margin-top: 27px;
}
.view {
  flex-grow: 3;
}
.icon {
  cursor: pointer;
  color: #e801be !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.eventContainer {
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
@media only screen and (max-width: 962px) {
  .root {
    width: 360px;
    height: 386px;
    width: auto;
  }
  .imageContainer {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
