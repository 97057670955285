.form-control {
  border: "1px solid #01214D" !important;
}

.form-control:focus {
  border: 2px solid #e402bc !important;
}

.selected-flag {
  padding: 0 !important;
}
.selected-flag:hover {
  background-color: transparent !important;
}
.flag-dropdown.open .selected-flag {
  background: transparent !important;
}
